.css-1impr83 {
  max-width: 1310px !important;
  overflow: hidden !important;
}

.swiper {
  max-width: 1280px;
  overflow: unset !important;
}

.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: #b5b5b5 !important;
}

.swiper-pagination-bullet-active {
  background-color: #555555 !important;
}

/* Custom styles for Swiper navigation buttons */
.swiper-button-prev,
.swiper-button-next {
  color: #c9c9c9 !important;
  transition: transform 0.2s ease;
}

/* Specific spacing adjustments */
.swiper-button-prev {
  transform: translateX(-25px);
}

.swiper-button-next {
  transform: translateX(25px);
}

@media screen and (max-width: 1330px) {
  .swiper-button-prev {
    transform: translateX(-10px);
    transform: translateY(45px);
  }

  .swiper-button-next {
    transform: translateX(10px);
    transform: translateY(45px);
  }
}
@media screen and (max-width: 768px) {
  .swiper-button-prev {
    transform: translateX(-10px);
    transform: translateY(45px);
  }

  .swiper-button-next {
    transform: translateX(10px);
    transform: translateY(45px);
  }
}
