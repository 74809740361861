@media (min-width: 992px) {
   #HomeNavbar .nav-link::before {
      content: " ";
      position: absolute;
      top: 35px;
      left: 0;
      width: 0;
      height: 2px;
      background-color: white;
      transition: width 0.3s ease;
    }
  
   #HomeNavbar .nav-link:hover::before {
      width: 100%;
    }
  }
  